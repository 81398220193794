<template>
  <v-card class="fill-height">
    <v-tabs v-model="activeTab" @input="updateSelectedTab">
      <v-tab v-show="enableTextEditor">{{ viewerEditorTabs.text.name }}</v-tab>
      <v-tab v-show="enableAnnotationEditor">{{
        viewerEditorTabs.annotation.name
      }}</v-tab>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card-text>
            <v-textarea
              v-show="enableTextEditor"
              v-model="text"
              :readonly="editable"
              rows="22"
              auto-grow
              :clearable="!editable"
              outlined
              background-color="#F5F5F5"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <base-button
              class="mb-4"
              v-show="enableTextEditor"
              :disabled="enableButtonSave"
              @click="saveText"
              >Save</base-button
            >
          </v-card-actions>
        </v-tab-item>

        <v-tab-item>
          <v-card-text>
            <annotation-editor
              v-show="enableAnnotationEditor"
              :annotation="annotation"
            ></annotation-editor>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";
import { viewerEditorTabs } from "@/model/documentViewer/webDocumentViewerModel";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "ViewerEditor",
  mixins: [reactiveMixin],
  components: {
    AnnotationEditor: () =>
      import("@/components/shared/core/editors/AnnotationEditor"),
    BaseButton: () => import("@/components/shared/base/BaseButton")
  },
  props: {
    record: undefined,
    annotation: {
      type: Object,
      default: undefined
    },
    ocrText: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: false
    },
    enableAnnotationEditor: {
      type: Boolean,
      default: false
    },
    enableTextEditor: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTab: 0,
      text: this.ocrText,
      editMode: true,
      viewerEditorTabs: viewerEditorTabs
    };
  },
  computed: {
    isTextTab() {
      return this.activeTab === viewerEditorTabs.text.value;
    },
    isAnnotationTab() {
      return this.activeTab === viewerEditorTabs.annotation.value;
    },
    textAreaLabel() {
      return "Ocr Text";
    },
    enableButtonSave() {
      return this.editable || !this.text;
    }
  },
  methods: {
    /**
     * Save Ocr Text
     */
    saveText() {
      this.$emit(eventNames.ocrTextSaved, this.text);
    },

    /**
     * Select Available Tab
     */
    updateSelectedTab() {
      if (this.enableTextEditor) {
        this.activeTab = 0;
      } else if (this.enableAnnotationEditor) {
        this.activeTab = 1;
      }
    }
  },

  watch: {
    ocrText() {
      this.text = this.ocrText;
    },
    enableTextEditor() {
      this.updateSelectedTab();
    },
    enableAnnotationEditor() {
      this.updateSelectedTab();
    }
  },

  mounted() {
    // Select available tab
    this.updateSelectedTab();
  }
};
</script>

<style></style>
